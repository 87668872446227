<template>
<div class="carousel-item">
    <div
        role="button" tabindex="0" class="jbg-direct-feature"
        v-on:click.self="navigateToBlogPage"
        v-on:keydown.enter="navigateToBlogPage"
    >
        <div class="overlay">
            <div class="cards-group">
                <div class="card tmp3">
                    <div class="logo">
                        <img src="/images/homepage/features/direct2025/tmp3.png" class="tmp3" alt="Trivia Murder Party 3 logo">
                    </div>
                    <NuxtLink :to="localeRoute({path: '/tmp3-teaser'})" class="cta-btn-container">
                        <button class="cta-btn">{{ $t('GAME_DETAIL.LEARN_MORE') }}</button>
                    </NuxtLink>
                </div>
                <div class="card jpp11">
                    <div class="logo">
                        <img src="/images/homepage/features/direct2025/pp11.png" class="jpp11" alt="Jackbox Party Pack 11 logo">
                    </div>
                    <NuxtLink :to="localeRoute({path: '/pp11-teaser'})" class="cta-btn-container">
                        <button class="cta-btn">{{ $t('GAME_DETAIL.LEARN_MORE') }}</button>
                    </NuxtLink>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    methods: {
        navigateToBlogPage() {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            void this.$router.push(this.localeRoute({
                path: '/blog/jackbox-direct-2025'
            }))
        }
    }
})
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.jbg-direct-feature {
    width: 100%;
    height: 100%;
    background-image: url('/images/homepage/features/direct2025/carousel-wide-bg.png');
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.24) 26.97%, rgba(0, 0, 0, 0.07) 42.52%, rgba(0, 0, 0, 0.00) 100%),
        image-set(url(/images/homepage/features/direct2025/carousel-wide-bg.png));
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
        background-size: cover;

    @include mq-xsmall {
        background-image: url('/images/homepage/features/direct2025/carousel-mobile-bg.png');
        background:
                linear-gradient(180deg, rgba(0, 0, 0, 0.00)48.54%, rgba(0, 0, 0, 0.5) 60.18%),
                image-set(url(/images/homepage/features/direct2025/carousel-mobile-bg.png));
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;
        align-items: end;
        justify-content: center;
        padding-bottom: 16px;
    }


    .overlay {
        position: relative;
        top: 45%;
        width: 100%;
        height: calc(100% - 45%);
        padding: 20px 45px;

        @include mq-xsmall {
            top: 66%;
            height: calc(100% - 66% + 16px);
            padding: 15px;
        }

        .cards-group {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;

            // Gradient Border
            @include mq-xsmall {
              background: linear-gradient(108deg, rgba(182, 224, 255, 0.09) 0%, rgba(182, 224, 255, 0.13) 100%);
                backdrop-filter: blur(6.5px);
                border-radius: 10px;
            }

            .card {
                position: relative;
                width: 284px;
                height: 100%;
                max-height: 100%;
                overflow: hidden;
                padding: 20px;
                color: #FFFFFF;
                background: linear-gradient(108.46deg, rgba(182, 224, 255, 0.088) 0%, rgba(182, 224, 255, 0.132) 100%);
                backdrop-filter: blur(6.5px);
                z-index: 0;
                border-radius: 10px;

                text-align: center;
                display: flex;
                gap: 12px;
                flex-direction: column;

                @include mq-small {
                    max-width: 180px;
                    padding: 14px;
                }
                @include mq-xsmall {
                    backdrop-filter: none;
                    background: none;
                }

                .logo {
                    height: 100%;
                    min-height: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                .cta-btn-container{
                    width: 100%;
                    .cta-btn {
                        border: 0;
                        height: 40px;
                        width: 100%;
                        min-height: 0;
                        color: #ffffff;
                        padding: 12px;
                        font-size: 14px;
                        line-height: 14px;
                        font-weight: 900;
                        text-wrap: nowrap;
                        text-transform: uppercase;
                        background: linear-gradient(92.89deg, #6AA4FE 0.06%, #6070E5 100.06%);
                        &:hover {
                            background: linear-gradient(92.89deg, #96bffe 0.06%, #6070E5 100.06%);
                            box-shadow: 0px 0px 23.98px 0px rgba(129, 177, 253, 0.64), 0px 0px 13.988px 0px rgba(129, 177, 253, 0.56), 0px 0px 6.994px 0px #81B1FD, 0px 0px 1.998px 0px #81B1FD, 0px 0px 0.999px 0px #81B1FD;
                        }
                        @include mq-xsmall {
                            height: 36px;
                            font-size: 12px;
                            line-height: 12px;
                            padding: 10px;
                        }
                    }
                }
            }
        }
    }
}
</style>
